<template>
  <v-layout row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      style="max-width: 100% !important;width: 100% !important;background-color:red;"
    >
      <template>
          <v-subheader
            v-if="label"
            style="left: 0px"
          >
            {{ label }}
          </v-subheader>
          <v-badge 
            color="red"
            :style="{
              'width': isFullWidth ? '100%' : null,
            }"
          >
            <template
              v-if="closed"
              v-slot:badge
            >
              <span
                v-if="closed"
                @click="onClearImage"
                style="cursor: pointer;"
              >
                X
              </span>
            </template>
            <v-card
              v-if="!icon"
              elevation="0"
              :style="{
                'width': isFullWidth ? '100%' : '120px',
              }"
            >
              <div
                v-if="!imageOld"
                style="backgroundColor: #e0e0e0 !important;height: 120px;border-radius: 10px;border: 0.5px solid #56a09930 !important;cursor:pointer;"
                :style="{
                  'width': isFullWidth ? '100%' : '120px',
                }"
                @click="handleOpenSelectImage"
              >
                <div style="margin-top: 30px;"
                  :style="{
                  'width': isFullWidth ? '100%' : '120px',
                }"
                >
                  <center><v-icon style="font-size: 60px;color: #FFFFFF80;">mdi-image-plus</v-icon></center>
                </div>
              </div>
              <v-img
                v-else
                :src="imageNew ? imageNew : imageOld ? imageOld : defaultImg"
                height="120px"
                :style="{
                  'width': isFullWidth ? `${mainFormWidth-100}px` : '120px',
                }"
                :lazy-src="imageOld"
                aspect-ratio="1.7"
                @click="handleOpenSelectImage"
                class="grey lighten-2"
                style="border-radius: 10px;border: 0.5px solid #56a09930 !important;"
              />
            </v-card>
            <v-card
              v-else
              elevation="0"
            >
              <v-btn
                :color="enterpriseColor"
                dark
                small
                absolute
                top
                right
                fab
                :elevation="5"
                style="margin-right:-35px;"
                @click="handleOpenSelectImage"
              >
                <v-icon>{{ icon }}</v-icon>
              </v-btn>
              <div
                v-if="!imageOld"
                style="backgroundColor: #e0e0e0 !important;width: 120px;height: 120px;border-radius: 10%;border: 0.5px solid #56a09930 !important;cursor:pointer;"
                @click="handleOpenSelectImage"
              >
                <div style="margin-top: 30px;">
                  <center><v-icon style="font-size: 60px;color: #FFFFFF80;">mdi-image-area</v-icon></center>
                </div>
              </div>
              <v-img
                v-else
                :src="imageNew ? imageNew : imageOld ? imageOld : defaultImg"
                height="120px"
                :lazy-src="imageOld"
                aspect-ratio="1.7"
                @click="handleOpenSelectImage"
                class="grey lighten-2"
                style="cursor:pointer;"
                :style="{
                  'width': isLogo ? '275px' : isFullWidth ? `${mainFormWidth-100}px` : '120px',
                  'border-radius': isLogo ? '10px 10px 0 0' : '10px',
                  'backgroundColor' : isLogo ? `${BGColor} !important` : '#e0e0e0 !important',
                  'border': isLogo ? '0' : '0.5px solid #56a09930 !important'
                }"
              />
            </v-card>
          </v-badge>
      </template>
      <v-card
        style="background-color: gray !important;"
      >
        <v-toolbar dark color="dtouch">
          <v-btn icon dark @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('common.selectImage') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <span style="color: red;" v-if="message"> {{ $t(message) }} </span>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :loading="loading"
              :disabled="loading"
              dark
              text
              @click="onSave"
            >
              {{ $t('common.ok') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <cropper
          v-if="image"
          classname="cropper"
          :src="image"
          :imageHeight="100"
          :imageWidth="100"
          :stencilProps="aspectRatio ? {
            aspectRatio: aspectRatio
          } : null"
          :backgroundClassname="backgroundColor"
          @change="handleChangeImage"
        />
        <div v-else class="button-wrapper" style="color: #FFFFFFB3;text-align: center;">
          <span class="button" @click="$refs.file.click()" style="color: #FFFFFFB3;text-align: center;">
            <input type="file" ref="file" @change="uploadImage($event)" accept="image/*">
            <v-icon style="font-size: 350px; color:#FFFFFFB3;">mdi-image-plus</v-icon>
          </span>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import { Cropper } from 'vue-advanced-cropper'
  export default {
    name: 'SelectImage',
    components: {
      Cropper
    },
    props: {
      isLogo: {
        type: Boolean,
        required: false,
        default: false
      },
      isFullWidth: {
        type: Boolean,
        required: false,
        default: false
      },
      enterpriseID: {
        type: String,
        required: false,
        default: null
      },
      color: {
        type: String,
        required: false,
        default: 'blue'
      },
      label: {
        type: String,
        required: false
      },
      icon: {
        type: String,
        required: false,
        default: null
      },
      aspectRatio: {
        type: Number,
        required: false,
        default: null
      },
      imageOld: {
        type: String,
        required: false
      },
      value: {
        type: String,
        required: false
      },
      closed: {
        type: Boolean,
        required: false
      },
    },
    data () {
      return {
        image: null,
        backgroundColor: null,
        defaultImg: './img/default.png',
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        imageNew: undefined,
        loading: false,
        percentage: 100,

        message: null,
      }
    },
    computed: {
      ...mapState('app',['mainFormWidth']),
      BGColor() {
        return this.$store.state.app.BGColor
      },
      enterpriseColor() {
        return this.$store.state.app.enterpriseColor
      },
      sizeW () {
        return window.innerWidth - 400
      },
    },
    watch: {
      color: {
          handler (newValue) {
            this.backgroundColor = newValue
          },
          deep: true
        }
    },
    mounted () {
      this.backgroundColor = this.color
    },
    methods: {
      handleOpenSelectImage() {
        this.image = null
        this.dialog = true
      },
      uploadImage(event) {
        this.message = null
        // Reference to the DOM input element
        var input = event.target
        let aux = input.files[0].size / 1024
        aux = aux / 1024
        
        if (aux <= 2) {
          // Ensure that you have a file before attempting to read it
          if (input.files && input.files[0]) {
              // create a new FileReader to read this image and convert to base64 format
              var reader = new FileReader()
              // Define a callback function to run, when FileReader finishes its job
              reader.onload = (e) => {
                  // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                  // Read image as base64 and set to imageData
                  this.image = e.target.result
              }
              // Start the reader job - read file as a data url (base64 format)
              reader.readAsDataURL(input.files[0])
          }
        } else {
          this.message = "common.error2mb"
        }   
      },
      handleChangeImage (aux) {
        this.imageNew = aux.canvas.toDataURL()
      },
      onClose () {
        this.image = null
        this.imageNew = null
        this.dialog = false
      },
      onSave () {
        if (this.enterpriseID) {
          this.loading = true
          const body = {
            newImage: this.imageNew,
            EnterpriseID: this.enterpriseID
          }
          
          api.addItem('utils/images', body)
            .then(response => {
              if (response.status === 200) {
                this.$emit('input', response.data)
                this.imageNew = undefined
                this.dialog = false
                this.loading = false
              }
            })
            .catch(error => {
              console.log('error', error)
              this.loading = false
            })
        }
      }
    }
  }
</script>
<style scoped>
  .v-dialog--fullscreen {
    width: 10px !important;
    max-width: 100% !important;
  }
  .select-image {
    max-width: 100% !important;
    width: 100% !important;
  }
  .upload-example-cropper {
    border: solid 1px #EEE;
    height: 300px;
    width: 100%;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .button {
    font-size: 36px;
    cursor: pointer;
    opacity: 0.5;
  }

  .button:hover {
    color: rgba(255, 255, 255, 0.4);
    opacity: 0.7;
  }

  .button input {
    display: none;
  }
</style>
