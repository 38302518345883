<template>
  <v-card
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :class="{ 'grey lighten-2': dragover }"
    :elevation="1"
  >
    <v-card-text 
      @click="handleOpenFileSearch"
      style="cursor: pointer;"
    >
      <input
        type="file"
        :accept="accept"
        v-show="false"
        ref="docpicker"
        @input="handleDocPickerChange"
      />
      <v-row class="d-flex flex-column" dense align="center" justify="center">
        <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
          mdi-video-plus
        </v-icon>
        <!-- <p>
          Drop your file(s) here, or click to select them.
        </p> -->
        <p>
          {{ $t('folders.dropFile', locale)}}
        </p>
      </v-row>
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <v-virtual-scroll
        v-else-if="uploadedFiles.length > 0"
        :items="uploadedFiles"
        item-height="50"
        height="60"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
                <span class="ml-3 text--secondary">
                  {{ item.size }} bytes</span
                >
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click.stop="handleRemoveFile(item.name)" icon>
                <v-icon> mdi-close-circle </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-card-text>
  </v-card>    
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: "upload-video-file",
    props: {
      oldValue: {
        type: Object,
        default: null
      },
      localeFile: {
        type: String,
        default: null
      },
      onDeleteFile: {
        type: Function,
        default: null
      },
      onChangeFile: {
        type: Function,
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      size: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        loading: false,
        accept: 'video/mp4,video/x-m4v,video/*',
        dragover: false,
        uploadedFiles: [],
        screenshot: null,
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      oldValue () {
        this.convertInfo()
      }
    },
    mounted () {
      this.convertInfo()
    },
    methods: {
      convertInfo () {
        if (this.oldValue) {
          if (this.uploadedFiles.length > 0) this.uploadedFiles = []          
          this.uploadedFiles.push(this.oldValue)
        } 
      },
      handleOpenFileSearch () {
        this.$refs.docpicker.click()
      },

      handleRemoveFile(fileName) {
        // Find the index of the
        const index = this.uploadedFiles.findIndex(
          file => file.name === fileName
        )
        // If file is in uploaded files remove it
        if (index > -1) this.uploadedFiles.splice(index, 1)
        if (this.onDeleteFile) this.onDeleteFile(this.localeFile, fileName)
      },

      onDrop(e) {
        this.dragover = false

        // If there are already uploaded files remove them
        if (this.uploadedFiles.length > 0) this.uploadedFiles = []

        // If user has uploaded multiple files but the component is not multiple throw error
        if (!this.multiple && e.dataTransfer.files.length > 1) {
          alert("Only one file can be uploaded at a time..")
          // this.$store.dispatch("addNotification", {
          //   message: "Only one file can be uploaded at a time..",
          //   colour: "error"
          // })
        }
        // Add each file to the array of uploaded files
        else
          e.dataTransfer.files.forEach(element =>
            this.uploadedFiles.push(element)
          )

        this.submit()
      },

      submit() {
        if (this.uploadedFiles.length > 0) this.handleChangeFile(this.uploadedFiles[0])
      },
      
      handleDocPickerChange (e) {
        if (this.uploadedFiles.length > 0) this.uploadedFiles = []
        this.uploadedFiles.push(...e.target.files)
        this.handleChangeFile(this.uploadedFiles[0])
      },
      handleChangeFile (v) {
        this.loading = true
        const reader = new FileReader()
        reader.onloadend = () => {
          // use a regex to remove data url part
          const base64 = reader.result
          this.handleCreateScreenshot(
            {
              name: v.name,
              size: v.size,
              base64
            }
          )
          /*
          const base64String = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "")
          this.$emit('change', {
            name: v.name,
            size: v.size,
            base64: base64String
          })
          */
        }
        reader.readAsDataURL(v)
      },
      handleCreateScreenshot (v) {
        const w = 150
        const h = this.size && this.size.w ? (this.size.h * w / this.size.w) : 84
        const that = this
        const media = new Audio(v.base64)
        media.onloadedmetadata = () => {
          media.duration // this would give duration of the video/audio file
          // capture frame
          const aux = document.createElement('video')
          aux.addEventListener('loadeddata', () => {
            aux.currentTime = 22
            /*
            setTimeout(() => {
                // if (that.loading === true || that.loading === undefined) that.loading = false
            }, 3000)
            */
          }, false)
          aux.addEventListener('seeked', () => {
            const c = document.createElement('canvas')
            const ctx = c.getContext('2d')
            c.width = w
            c.height = h
            ctx.drawImage(aux, 0, 0, w, h)
            this.screenshot = c.toDataURL()
            this.loading = false
            this.$emit('change', {
              name: v.name,
              size: v.size,
              base64: v.base64,
              duration: media.duration | 0, // convert float to int
              screenshot: that.screenshot,
            })
          }, false)
          aux.preload = "auto"
          aux.src = v.base64
          // capture frame
        }
      }
    }
  }
</script>
