var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":""}},[_c('v-dialog',{staticStyle:{"max-width":"100% !important","width":"100% !important","background-color":"red"},attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[(_vm.label)?_c('v-subheader',{staticStyle:{"left":"0px"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('v-badge',{style:({
            'width': _vm.isFullWidth ? '100%' : null,
          }),attrs:{"color":"red"},scopedSlots:_vm._u([(_vm.closed)?{key:"badge",fn:function(){return [(_vm.closed)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.onClearImage}},[_vm._v(" X ")]):_vm._e()]},proxy:true}:null],null,true)},[(!_vm.icon)?_c('v-card',{style:({
              'width': _vm.isFullWidth ? '100%' : '120px',
            }),attrs:{"elevation":"0"}},[(!_vm.imageOld)?_c('div',{staticStyle:{"backgroundColor":"#e0e0e0 !important","height":"120px","border-radius":"10px","border":"0.5px solid #56a09930 !important","cursor":"pointer"},style:({
                'width': _vm.isFullWidth ? '100%' : '120px',
              }),on:{"click":_vm.handleOpenSelectImage}},[_c('div',{staticStyle:{"margin-top":"30px"},style:({
                'width': _vm.isFullWidth ? '100%' : '120px',
              })},[_c('center',[_c('v-icon',{staticStyle:{"font-size":"60px","color":"#FFFFFF80"}},[_vm._v("mdi-image-plus")])],1)],1)]):_c('v-img',{staticClass:"grey lighten-2",staticStyle:{"border-radius":"10px","border":"0.5px solid #56a09930 !important"},style:({
                'width': _vm.isFullWidth ? ((_vm.mainFormWidth-100) + "px") : '120px',
              }),attrs:{"src":_vm.imageNew ? _vm.imageNew : _vm.imageOld ? _vm.imageOld : _vm.defaultImg,"height":"120px","lazy-src":_vm.imageOld,"aspect-ratio":"1.7"},on:{"click":_vm.handleOpenSelectImage}})],1):_c('v-card',{attrs:{"elevation":"0"}},[_c('v-btn',{staticStyle:{"margin-right":"-35px"},attrs:{"color":_vm.enterpriseColor,"dark":"","small":"","absolute":"","top":"","right":"","fab":"","elevation":5},on:{"click":_vm.handleOpenSelectImage}},[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1),(!_vm.imageOld)?_c('div',{staticStyle:{"backgroundColor":"#e0e0e0 !important","width":"120px","height":"120px","border-radius":"10%","border":"0.5px solid #56a09930 !important","cursor":"pointer"},on:{"click":_vm.handleOpenSelectImage}},[_c('div',{staticStyle:{"margin-top":"30px"}},[_c('center',[_c('v-icon',{staticStyle:{"font-size":"60px","color":"#FFFFFF80"}},[_vm._v("mdi-image-area")])],1)],1)]):_c('v-img',{staticClass:"grey lighten-2",staticStyle:{"cursor":"pointer"},style:({
                'width': _vm.isLogo ? '275px' : _vm.isFullWidth ? ((_vm.mainFormWidth-100) + "px") : '120px',
                'border-radius': _vm.isLogo ? '10px 10px 0 0' : '10px',
                'backgroundColor' : _vm.isLogo ? (_vm.BGColor + " !important") : '#e0e0e0 !important',
                'border': _vm.isLogo ? '0' : '0.5px solid #56a09930 !important'
              }),attrs:{"src":_vm.imageNew ? _vm.imageNew : _vm.imageOld ? _vm.imageOld : _vm.defaultImg,"height":"120px","lazy-src":_vm.imageOld,"aspect-ratio":"1.7"},on:{"click":_vm.handleOpenSelectImage}})],1)],1)],_c('v-card',{staticStyle:{"background-color":"gray !important"}},[_c('v-toolbar',{attrs:{"dark":"","color":"dtouch"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('common.selectImage')))]),_c('v-spacer'),(_vm.message)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.message))+" ")]):_vm._e(),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"dark":"","text":""},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t('common.ok'))+" ")])],1)],1),(_vm.image)?_c('cropper',{attrs:{"classname":"cropper","src":_vm.image,"imageHeight":100,"imageWidth":100,"stencilProps":_vm.aspectRatio ? {
          aspectRatio: _vm.aspectRatio
        } : null,"backgroundClassname":_vm.backgroundColor},on:{"change":_vm.handleChangeImage}}):_c('div',{staticClass:"button-wrapper",staticStyle:{"color":"#FFFFFFB3","text-align":"center"}},[_c('span',{staticClass:"button",staticStyle:{"color":"#FFFFFFB3","text-align":"center"},on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('input',{ref:"file",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadImage($event)}}}),_c('v-icon',{staticStyle:{"font-size":"350px","color":"#FFFFFFB3"}},[_vm._v("mdi-image-plus")])],1)])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }