<template>
    <v-stage
      v-if="bgImg"
      ref="stage"
      :config="configKonva"
      @mousedown="handleStageMouseDown"
      @touchstart="handleStageMouseDown"
      @dragstart="handleDragstart"
      @dragend="handleDragend"
      :style="{
              'background-image': `url('${bgImg}')`,
              'background-size': 'cover',
              'width': `${configKonva.width}px`,
              'height': `${configKonva.height}px`,
            }"
  
    >
      <v-layer ref="layer">
        <v-transformer ref="transformer" />
        <v-image
          v-if="image"
          :config="{
            image: image,
            x: 0,
            y: 0,
            id: 'qr',
            scaleX: scaleQR,
            scaleY: scaleQR,
            draggable: true,
            name: 'qr',
          }"
          @transformend="handleTransformEnd"
        />
        <!--v-star
          v-for="item in list"
          :key="item.id"
          :config="{
            x: item.x,
            y: item.y,
            rotation: item.rotation,
            id: item.id,
            numPoints: 5,
            innerRadius: 30,
            outerRadius: 50, fill: '#89b717',
            opacity: 0.8,
            draggable: true,
            scaleX: dragItemId === item.id ? item.scale * 1.2 : item.scale,
            scaleY: dragItemId === item.id ? item.scale * 1.2 : item.scale,
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: dragItemId === item.id ? 15 : 5,
            shadowOffsetY: dragItemId === item.id ? 15 : 5,
            shadowOpacity: 0.6
          }"
        /-->
      </v-layer>
    </v-stage>
</template>

<script>
export default {
  props: {
    qrWidth: {
      type: Number,
      default: null,
    },
    size: {
      type: Object,
      default: null,
    },
    qrImg: {
      type: String,
      default: null,
    },
    bgImg: {
      type: String,
      default: null,
    },
    force: {
      type: String,
      default: null,
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      width: null,
      height: null,
      image: null,
      dragItemId: null,
      configKonva: {
        width: this.size.width,
        height: this.size.height
      },
      selectedShapeName: '',
      scaleQR: 1,
    };
  },
  watch: {
    qrImg (v) {
      this.image = null
      this.handlePrepareImg(v)
    },
    force () {
      this.handlePrepareSave()
    },
  },
  mounted() {
    this.prepareSize()
  },
  methods: {
    handlePrepareSave () {
      const transformerNode = this.$refs.transformer.getNode()
      transformerNode.nodes([])
      setTimeout(() => { this.onSave() }, 1000)
    },
    prepareSize () {
      this.configKonva = this.size
      /*
      console.log('jhm2', aux)
      const aw = width
      const ah = height
      const bw = this.size.w
      const bh = this.size.h

      let scale = 1
      if (aw/bw < ah/bh) { // determinamos si el mayor scale factor es el width o el height
        if (aw/bw < 1) scale = aw/bw
      } else {
        if (ah/bh < 1) scale = ah/bh
      }

      // una vez determinado el scale factor actualizamos el width y height
      this.configKonva.width = bw * scale
      this.configKonva.height = bh * scale
      */
    },
    handlePrepareImg (v) {
      // ini scale
      if (this.configKonva.width/this.qrWidth < this.configKonva.height/this.qrWidth) { // determinamos si el mayor scale factor es el width o el height
        if (this.configKonva.width/this.qrWidth < 1) this.scaleQR = this.configKonva.width/this.qrWidth
      } else {
        if (this.configKonva.height/this.qrWidth < 1) this.scaleQR = this.configKonva.height/this.qrWidth
      }
      // end scale
      
      const image = new window.Image()
      image.src = v
      image.onload = () => {
        // set image only when it is loaded
        this.image = image
      }
    },
    handleTransformEnd(e) {
      console.log(e)
      /*
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const rect = this.rectangles.find(
        (r) => r.name === this.selectedShapeName
      );
      // update the state
      rect.x = e.target.x();
      rect.y = e.target.y();
      rect.rotation = e.target.rotation();
      rect.scaleX = e.target.scaleX();
      rect.scaleY = e.target.scaleY();

      // change fill
      rect.fill = Konva.Util.getRandomColor();
      */
    },
    handleStageMouseDown(e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = ''
        this.updateTransformer()
        return
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer = e.target.getParent().className === 'Transformer'
      if (clickedOnTransformer) return

      const name = e.target.name()
      this.selectedShapeName = name

      this.updateTransformer()
    },
    handleDragstart(e) {
      // save drag element:
      this.dragItemId = e.target.id()
      /*// move current element to the top:
      const item = this.list.find(i => i.id === this.dragItemId)
      const index = this.list.indexOf(item)
      this.list.splice(index, 1)
      this.list.push(item)*/
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode()
      const stage = transformerNode.getStage()
      const { selectedShapeName } = this

      const selectedNode = stage.findOne('.' + selectedShapeName)
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) return

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode])
      } else {
        // remove transformer
        transformerNode.nodes([])
      }
    },
    handleDragend () {
      this.dragItemId = null
    }
  },
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>
