<template>
<div>
  <cropper
    v-if="image"
    classname="cropper"
    :src="image"
    :imageHeight="imageHeight"
    :imageWidth="imageWidth"
    :stencilProps="aspectRatio ? { aspectRatio: aspectRatio } : {}"
    :backgroundClassname="backgroundColor"
    @change="handleChangeImage"
  />
  <div v-else class="button-wrapper">
    <span class="button" @click="handleTest">
      <input type="file" ref="file" @change="handleUpdateImage($event)" accept="image/*">
      <v-icon class="icon">mdi-image-plus</v-icon>
    </span>
  </div>
</div>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
export default {
  name: 'CaptureImage',
  components: {
    Cropper
  },
  props: {
    onChange: {
      type: Function,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: 'blue',
    },
    imageWidth: {
      type: Number,
      default: null,
    },
    imageHeight: {
      type: Number,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
    folder: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: 2048,
    },
  },
  data: () => ({
    message: null,
    image: null,
    newImage: null,
  }),
  methods: {
    handleTest () {
      this.$refs.file.click ()
    },
    handleChangeImage (v) {
      this.newImage = {
        base64: v.canvas.toDataURL(),
        folder: this.folder,
        ext: 'png',
      }
      this.onChange(this.newImage)
    },
    handleUpdateImage (v) {
      this.message = null
      // Reference to the DOM input element
      var input = v.target
      let aux = input.files[0].size / 1024
      aux = aux / 1024
      if (aux > (this.limit ? this.limit / 1024 : 2)) {
        alert (`La imágen no debe superar los ${this.limit ? this.limit : 2048}KB`)
        return
      }
      
      if (aux <= this.limit ? this.limit / 1024 : 2) {
        // Ensure that you have a file before attempting to read it
        if (input.files && input.files[0]) {
          // create a new FileReader to read this image and convert to base64 format
          const reader = new FileReader()
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e) => {
            // console.log('image  l,',e.target.result)
              // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
              // Read image as base64 and set to imageData
            
              this.image = e.target.result
          }
          // Start the reader job - read file as a data url (base64 format)
          reader.readAsDataURL(input.files[0])
        }
      } else this.message = 'common.error2mb'   
    }
  }
}
</script>
<style scoped>
.upload-example-cropper {
  border: solid 1px #EEE;
  height: 300px;
  width: 100%;
}
.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  color: #FFFFFFB3;
  text-align: center;
}
.button-wrapper .button {
  font-size: 36px;
  cursor: pointer;
  opacity: 0.5;
  color: #FFFFFFB3;
  text-align: center;
}
.button-wrapper .button:hover {
  color: rgba(255, 255, 255, 0.4);
  opacity: 0.7;
}
.button-wrapper .button input {
  display: none;
}
.button-wrapper .icon {
  font-size: 350px;
  color: #FFFFFFB3;
}
</style>

