var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.bgImg)?_c('v-stage',{ref:"stage",style:({
          'background-image': ("url('" + _vm.bgImg + "')"),
          'background-size': 'cover',
          'width': ((_vm.configKonva.width) + "px"),
          'height': ((_vm.configKonva.height) + "px"),
        }),attrs:{"config":_vm.configKonva},on:{"mousedown":_vm.handleStageMouseDown,"touchstart":_vm.handleStageMouseDown,"dragstart":_vm.handleDragstart,"dragend":_vm.handleDragend}},[_c('v-layer',{ref:"layer"},[_c('v-transformer',{ref:"transformer"}),(_vm.image)?_c('v-image',{attrs:{"config":{
        image: _vm.image,
        x: 0,
        y: 0,
        id: 'qr',
        scaleX: _vm.scaleQR,
        scaleY: _vm.scaleQR,
        draggable: true,
        name: 'qr',
      }},on:{"transformend":_vm.handleTransformEnd}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }