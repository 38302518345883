<template>
  <div v-if="simpleButton">
    <v-btn @click="handleOpenFileSearch" color="primary">
      {{ $t('common.selectImage', locale) }}
    </v-btn>
    <input
      type="file"
      accept="image/png, image/jpeg"
      v-show="false"
      ref="docpicker"
      @input="handleDocPickerChange"
    />
  </div>
  <v-card
    v-else
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :class="{ 'grey lighten-2': dragover }"
    :elevation="1"
  >
    <v-card-text 
      @click="handleOpenFileSearch"
      style="cursor: pointer;"
    >
      <v-img
        v-if="backgroundImage"
        :src="backgroundImage"
        width="100%"
        height="auto"
        class="grey darken-4"
        :style="{ 'border-radius': circle ? '50%' : null }"
      />
      <input
        type="file"
        accept="image/png, image/jpeg"
        v-show="false"
        ref="docpicker"
        @input="handleDocPickerChange"
      />
      <v-row class="d-flex flex-column" dense align="center" justify="center">
        <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
          mdi-image-plus
        </v-icon>
        <!-- <p>
          Drop your file(s) here, or click to select them.
        </p> -->
        <p style="margin-bottom:0px;">
          {{ $t('folders.dropFile', locale)}}
        </p>
        <small>{{size.w}}px x {{size.h}}px – 72ppp, Max. 5Mb</small>
      </v-row>
    </v-card-text>
  </v-card>    
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: "upload-file-item",
    props: {
      info: {
        type: Object,
        default: null
      },
      localeFile: {
        type: String,
        default: null
      },
      onOpenTranslate: {
        type: Function,
        default: null
      },
      onDeleteFile: {
        type: Function,
        requied: true
      },
      multiple: {
        type: Boolean,
        default: false
      },
      backgroundImage: {
        type: String,
        default: null,
      },
      size: {
        type: Object,
        default: null,
      },
      simpleButton: {
        type: Boolean,
        default: false,
      },
      circle: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dragover: false,
        uploadedFiles: [],
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      info () {
        this.convertInfo()
      }
    },
    mounted () {
      this.convertInfo()
    },
    methods: {
      convertInfo () {
        if (this.info && this.info[this.localeFile]) {
          if (this.uploadedFiles.length > 0) this.uploadedFiles = []          
          this.uploadedFiles.push(this.info[this.localeFile])
        } 
      },
      handleOpenFileSearch () {
        this.$refs.docpicker.click()
      },

      removeFile(fileName) {
        // Find the index of the
        const index = this.uploadedFiles.findIndex(
          file => file.name === fileName
        )

        // If file is in uploaded files remove it
        if (index > -1) this.uploadedFiles.splice(index, 1)
        if (this.onDeleteFile) this.onDeleteFile(this.localeFile, fileName)
      },

      onDrop(e) {
        this.dragover = false

        // If there are already uploaded files remove them
        if (this.uploadedFiles.length > 0) this.uploadedFiles = []

        // If user has uploaded multiple files but the component is not multiple throw error
        if (!this.multiple && e.dataTransfer.files.length > 1) {
          alert("Only one file can be uploaded at a time..")
          // this.$store.dispatch("addNotification", {
          //   message: "Only one file can be uploaded at a time..",
          //   colour: "error"
          // })
        }
        // Add each file to the array of uploaded files
        else
          e.dataTransfer.files.forEach(element =>
            this.uploadedFiles.push(element)
          )

        this.submit()
      },

      submit() {
        if (this.uploadedFiles.length > 0) this.handleChangeFile(this.uploadedFiles[0])
      },
      
      handleDocPickerChange (e) {
        if (this.uploadedFiles.length > 0) this.uploadedFiles = []
        this.uploadedFiles.push(...e.target.files)
        this.handleChangeFile(this.uploadedFiles[0])
      },
      handleChangeFile (v) {
        const reader = new FileReader()
        reader.onloadend = () => {
          // use a regex to remove data url partç
          const base64 = reader.result
          /*
          const base64String = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "")
          */
          this.$emit('change', {
            name: v.name,
            size: v.size,
            base64,
          })
        }
        reader.readAsDataURL(v)
        // reader.readAsText(v)

        // if(this.onChangeFile) this.onChangeFile(v, this.localeFile)
      }
    }
  }
</script>
