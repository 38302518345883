<template>
  <v-layout wrap>
    <v-flex xs6
      style="padding-top: 20px;"
    >
      <v-icon v-if="isTemp && status" :color="status.date ? 'green' : 'red'"> mdi-circle-medium</v-icon>
      <v-icon v-if="isTemp && status" :color="status.time ? 'green' : 'red'"> mdi-circle-medium</v-icon>
    </v-flex>
    <v-flex xs5 style="text-align: right;padding-top:20px;padding-right:10px;">
      <custom-label :label="$t('availability.isTemp', locale)" />
    </v-flex>
    <v-flex xs1>
      <center>
        <v-switch
          v-model="isTemp"
          color="primary"
          :value="isTemp"
          hide-details
          @change="handleChange"
        />
      </center>
    </v-flex>
    <v-layout wrap v-if="isTemp" align-center>
      <v-flex xs12 style="margin-top: 20px;">
        <b>{{ $t('availability.periodicity', locale) }}:</b>
      </v-flex>
      <v-flex xs12>
        <center style="margin-top: 10px;margin-bottom:20px;">
          <v-btn-toggle
            v-model="availability.Per"
            color="primary"
            borderless
            @change="handleChange" 
          >
            <v-btn v-if="drawer" :value="0">
              {{ $t('availability.allDays', locale) }}
            </v-btn>
            <v-btn v-else :value="0">
              <v-icon>mdi-all-inclusive</v-icon>
            </v-btn>

            <v-btn v-if="drawer" :value="1">
              {{ $t('availability.oneDay', locale) }}
            </v-btn>
            <v-btn v-else :value="1">
              <v-icon>mdi-numeric-1</v-icon>
            </v-btn>

            <v-btn v-if="drawer" :value="2">
              {{ $t('availability.recurrence', locale) }}
            </v-btn>
            <v-btn v-else :value="2">
              <v-icon>mdi-calendar-multiselect</v-icon>
            </v-btn>

          </v-btn-toggle>
        </center>
        <!--v-radio-group v-model="availability.Per" row @change="handleChange" color="primary">
          <v-radio :label="$t('availability.allDays', locale)" :value="0"></v-radio>
          <v-radio :label="$t('availability.oneDay', locale)" :value="1"></v-radio>
          <v-radio :label="$t('availability.recurrence', locale)" :value="2"></v-radio>
        </v-radio-group-->
      </v-flex>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12>
            <dialog-date-picker
              v-model="availability.DateStart"
              :locale="locale"
              :label="availability.Per !== 1 ? $t('availability.dateStart', locale) : $t('availability.date', locale)"
              @input="handleChange"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12>
            <v-switch
              v-if="availability.Per !== 1"
              v-model="availability.IsAllDays"
              :label="$t('availability.noEndDay', locale)"
              color="primary"
              :value="availability.IsAllDays"
              hide-details
              @change="handleChange"
              style="margin-bottom: 20px"
            />
            <!--v-checkbox
              v-if="availability.Per !== 1"
              v-model="availability.IsAllDays"
              :value="availability.IsAllDays"  
              :label="$t('availability.noEndDay', locale)"
              @change="handleChange"
            /-->
            <dialog-date-picker
              v-if="!availability.IsAllDays && availability.Per !== 1"
              v-model="availability.DateEnd"
              :locale="locale"
              :label="$t('availability.dateEnd', locale)"
              @input="handleChange"
            />
          </v-flex>
          <v-flex xs12>
            <v-switch
              v-model="availability.IsAllTimes"
              :label="$t('availability.isAllTimes', locale)"
              color="primary"
              :value="availability.IsAllTimes"
              hide-details
              @change="handleChange"
              style="margin-bottom: 20px"
            />
            <!--v-checkbox
              @change="handleChange"
              v-model="availability.IsAllTimes"
              :value="availability.IsAllTimes"
              :label="$t('availability.isAllTimes', locale)"
            /-->
          </v-flex>
          <v-flex xs12 v-if="!availability.IsAllTimes">
            <interval-times
              :label="`${$t('availability.timeStart', locale)}:`"
              v-model="availability.TimeStart"
              @input="handleChange"
            />
          </v-flex>
          <v-flex xs12 v-if="!availability.IsAllTimes">
            <interval-times
              :label="`${$t('availability.timeEnd', locale)}___:`"
              v-model="availability.TimeEnd"
              @input="handleChange"
            />
          </v-flex>
        </v-layout>
      </v-flex>
       <v-flex xs12 style="padding-right:10px;" v-if="availability.Per === 2">
        <b>{{ $t('availability.recurrence', locale) }}:</b>
      </v-flex>
      <v-flex xs12 v-if="availability.Per === 2">
        <center style="margin-top: 10px;margin-bottom:20px;">
          <v-btn-toggle
            v-model="availability.Rec"
            color="primary"
            borderless
            @change="handleChange" 
          >
            <v-btn :value="1">
              {{ $t('availability.byWeek', locale) }}
            </v-btn>

            <v-btn :value="0">
              {{ $t('availability.byMonth', locale) }}
            </v-btn>

          </v-btn-toggle>
        </center>
        <!--v-radio-group v-model="availability.Rec" row @change="handleChange">
          <v-radio :label="$t('availability.byWeek', locale)" :value="1"></v-radio>
          <v-radio :label="$t('availability.byMonth', locale)" :value="0"></v-radio>
        </v-radio-group-->
      </v-flex>
      <v-flex xs12 v-if="availability.Per === 2 && availability.Rec === 0">
        <b>{{ $t('availability.byMonth', locale) }}:</b>
        <v-row style="margin-top: 10px;margin-bottom: 20px;">
          <v-col
            cols="3"
            sm="3"
            md="3"
            v-for="(day, indexDayOfMonth) in 31"
            :key="`dayOfMonth${indexDayOfMonth}`"
          >
          <v-btn
            block
            :color="availability.DayOfMonth.indexOf(indexDayOfMonth + 1) >= 0 ? 'primary' : null"
            @click="handleChangeDayOfMonth(indexDayOfMonth + 1)"
          >
              {{ (indexDayOfMonth+1).toString() }}
            </v-btn>
          </v-col>
        </v-row>
        <!--v-container row>
          <v-checkbox
            v-model="availability.DayOfMonth"
            v-for="(day, indexDayOfMonth) in 31"
            :key="`dayOfMonth${indexDayOfMonth}`"
            :label="(indexDayOfMonth+1).toString()"
            :value="indexDayOfMonth+1"
            style="width:50px;margin-right: 20px;"
            @change="handleChange"
            multiple
          />
        </v-container-->
      </v-flex>
      <v-flex xs12 v-if="availability.Per === 2 && availability.Rec === 1">
        <b>{{ $t('availability.byWeek', locale) }}:</b>
        <v-row style="margin-top: 10px;margin-bottom: 20px;">
          <v-col
            cols="4"
            sm="4"
            md="4"
            v-for="(day, indexDayOfweek) in 7"
            :key="`dayOfWeek${indexDayOfweek}`"
          >
            <v-btn
              block
              :color="availability.DayOfWeek.indexOf(indexDayOfweek === 6 ? 0 : indexDayOfweek+1) >= 0 ? 'primary' : null"
              @click="handleChangeDayOfWeek(indexDayOfweek === 6 ? 0 : indexDayOfweek+1)"
            >
              {{ $t(`common.s${indexDayOfweek}`, locale).substring(0, 3) }}
            </v-btn>
          </v-col>
        </v-row>
        <!--v-container row>
          <v-checkbox
            v-model="availability.DayOfWeek"
            v-for="(day, indexDayOfweek) in 7"
            :key="`dayOfWeek${indexDayOfweek}`"
            :label="$t(`common.s${indexDayOfweek}`, locale)"
            :value="indexDayOfweek === 6 ? 0 : indexDayOfweek+1"
            style="width:100px;margin-right: 20px;"
            @change="handleChange"
            multiple
          />
        </v-container-->
      </v-flex>
      <v-flex xs12 v-if="availability.Per === 2">
        <v-text-field
          :label="$t('availability.alternation', locale)"
          outlined
          v-model="availability.Alt"
          @input="handleChange"
          type="number"
          />
      </v-flex>
    </v-layout>
  </v-layout>
</template>
<script>
  const defaultAvailability = {
    Per: 0,
    Rec: 1,
    IsAllDays: true,
    IsAllTimes: true,
    DayOfMonth: [],
    DayOfWeek: [],
    Alt: 1,
    TimeStart: 120,
    TimeEnd: 120,
    DateStart: new Date(),
    DateEnd: new Date(),
  }

  import { mapState } from 'vuex'
  import utils from '@/services/utils'
  import CustomLabel from '@/components/CustomLabel'
  import DialogDatePicker from './DialogDatePicker'
  import IntervalTimes from './intervalTimes/Index'

  export default{
    components: {
      DialogDatePicker,
      IntervalTimes,
      CustomLabel,
    },
    props: {
      value: {
        type: Object,
        default: null,
      },
      locale: {
        type: String,
        required: true
      },
      inPanel: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isTemp: this.availability ? true : false,
        availability: null,
        status: null,
      }
    },
    computed: {
      ...mapState('app',['drawer']),
      timezone () {
        return this.$store.state.app.timezone
      }
    },
    watch: {
      value (v) {
        this.isTemp = v ? true : false
        this.availability = v
        if (this.availability && !this.inPanel) this.status = utils.checkAvailability(this.availability, 0, this.timezone)
      },
      isTemp (v, old) {
        if(v && old !== v) {
          if(!this.value) this.availability = defaultAvailability
          this.handleChange()
        }
      },
      status (v) {
        if (this.onChangeStatus)
          if (this.isTemp) this.onChangeStatus (v)
          else this.onChangeStatus(null)
      }
    },
    mounted () {
      this.isTemp = this.value ? true : false
      if (this.isTemp) {
        this.availability = this.value
        if(this.availability && !this.inPanel) this.status = utils.checkAvailability(this.availability, 0, this.timezone)
      }
    },
    methods: {
      handleChange () {
        if (this.isTemp && this.availability && !this.inPanel) this.status = utils.checkAvailability(this.availability, 0, this.timezone)
        this.$emit('change', this.isTemp ? this.availability : null)
      },
      handleChangeDayOfMonth (v) {
        const index = this.availability.DayOfMonth.indexOf(v)
        if (index >= 0) this.availability.DayOfMonth.splice(index, 1)
        else this.availability.DayOfMonth.push(v)
        
        this.handleChange()
      },
      handleChangeDayOfWeek (v) {
        const index = this.availability.DayOfWeek.indexOf(v)
        if (index >= 0) this.availability.DayOfWeek.splice(index, 1)
        else this.availability.DayOfWeek.push(v)
        
        this.handleChange()
      }
    }
  }
</script>
