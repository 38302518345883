<template>
  <div v-if="config">
    <v-row>
      {{editedID}}
      <v-col
        v-for="field of config.form"
        :key="field.ID"
        cols="12"
        sm="12"
      >
        <v-text-field
          v-if="field.FieldType === 'text'"
          :label="getTranslation(field.Name, locale)"
          v-model="editedItem[field.ID]"
          hide-details
          outlined
          @input="handleChange"
        />
        <div
          v-if="['imagen', 'polaroid'].indexOf(field.FieldType) >= 0"
        >
          <small>
            {{ getTranslation(field.Name, locale) }} :
          </small>
          <select-image 
            :key="lmu"
            :image="editedItem[field.ID]"
            :onChange="(v) => handleChangeImage(field.ID, v)"
            :locale="locale"
            :imageWidth="field.Width"
            :imageHeight="field.Height"
            :aspectRatio="field.Width/field.Height"
            :folder="config.WorkspaceID"
            style="margin-top: 0; margin-left: 0px;width:100%;"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import SelectImage from '@/components/selectImage/Index'
export default {
  components: {
    SelectImage,
  },
  props: {
    editedID: {
      type: String,
      default: null,
    },
    customContentTypeID: {
      type: String,
      required: true,
    },
    isClone: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    lmu: null,
    config: null,
    editedItem: {}
  }),
  watch: {
    editedID () {
      this.handleGetData()
    },
    customContentTypeID () {
      this.handleGetFormData()
    },
  },
  mounted () {
    this.handleGetFormData()
    this.handleGetData()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    async handleGetData () {
      try {
        this.editedItem={}
        if (!this.editedID || !this.config) return
        this.editedItem = await new Promise((resolve, reject) => api.getAllWithoutLimit ('dviewcontent', `v1/private/custom-content-types/${this.editedID}/values`)
          .then(response => {
            if (!response) return
            const result = {}
            for (const field of this.config.form) {
              const aux = response.filter(x => x.FieldID === field.ID).map(item => item.Value).shift()
              result[field.ID] = aux
            }
            resolve(result)
          })
          .catch (error => {
            console.log(error)
            reject(error)
          }))
        if (this.isClone) this.handleChange()
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetFormData () {
      try {
        this.config = null
        this.config = await new Promise((resolve, reject) => api.getItem ('dviewcontent', `v1/private/custom-content-types/`, this.customContentTypeID)
          .then(response => {
            if (!response) return
            response.form = response.form.map(item => {
              item.Name = item.Name ? JSON.parse(item.Name) : null
              return item
            })
            resolve(response)
          })
          .catch (error => {
            console.log(error)
            reject(error)
          }))
        this.handleGetData()
        if (this.isClone) this.handleChange()
      } catch (error) {
        console.log(error)
      }
    },
    handleChangeImage (id, v) {
      this.editedItem[`newImage_${id}`] = v
      this.editedItem[id]= v.base64
      this.lmu = new Date().toISOString()
      this.handleChange()
    },
    handleChange () {
      this.onChange(this.editedItem)
    }
  },
}
</script>

