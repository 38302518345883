<template>
  <v-container style="background-color: #CCCCCC80;">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-text-field
          v-model="title"
          outlined
          :label="$t('content.event.title', locale)"
          hide-details
        />
      </v-col>
      <v-col
        v-if="contentType === 'event'"
        cols="12"
        sm="12"
        md="12"
      >
        <v-text-field
          v-model="subtitle"
          outlined
          :label="$t('content.event.subtitle', locale)"
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-text-field
          v-model="place"
          outlined
          :label="$t('content.event.place', locale)"
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-text-field
          v-model="description"
          outlined
          :label="$t('content.event.description', locale)"
          hide-details
        />
      </v-col>
      <v-col
        v-if="contentType === 'event'"
        cols="12"
        sm="12"
        md="12"
      >
        <v-text-field
          v-model="price"
          outlined
          :label="$t('content.event.price', locale)"
          hide-details
        />
      </v-col>
      <v-col
        v-if="contentType === 'event'"
        cols="12"
        sm="12"
        md="12"
      >
        template
        plantilla (cuadrada, circular, polaroid, promoción)
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        image
      </v-col>
    </v-row>
  <div 
      v-if="contentType === 'event'"
    >
    título<br/>
    lugar o fecha<br/>
    descripción<br/>
    texto destacado / precio<br/>
    <br/>

    plantilla (cuadrada, circular, polaroid, promoción)
    <br/>
    <br/>
    imagen 750x750px
  </div>
  <div 
      v-else-if="contentType === 'eventCalendar'"
    >
    título<br/>
    lugar o fecha<br/>
    descripción<br/>
    <br/>
    <br/>
    imagen 636x900px
  </div>
  </v-container>
</template>
<script>
export default {
  props: {
    contentType: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    title: null,
    subtitle: null,
    place: null,
    description: null,
    price: null,
  }),
  methods: {
    handleChange () {
      this.$emit('change', this.editedItem)
    },
  },
}
</script>

