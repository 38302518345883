<template>
  <div>
    <v-toolbar
      elevation="0"     
    >
    <v-icon large style="margin-right:10px;">{{ icon }}</v-icon>
      <v-toolbar-title>
        {{ isNew ? $t('common.create', locale) : isClone ? $t('common.clone', locale) : $t('common.edit', locale) }} {{ title.toLowerCase() }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Name"
            :label="$t('content.name', locale)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <!--v-col
          v-if="allowedTypes.length > 1 "
          cols="12"
          sm="12"
        >
          <custom-label
            :label="$t('content.type', locale).concat(':')"
            left
          />
        </v-col-->
        <v-col
          v-if="allowedTypes.length > 1"
          cols="12"
          sm="12"
          md="12"
        >
          <v-select
            v-model="editedItem.Type"
            :items="allowedTypes"
            :label="$t('content.type', locale)"
            item-text="name"
            item-value="value"
            outlined
            hide-details
          />
          <!--v-btn-toggle
            v-model="editedItem.Type"
            color="primary"
          >
            <v-btn
              v-for="item in allowedTypes"
              :key="item"
              :value="item"
            >
              {{ item }}
            </v-btn>
          </v-btn-toggle-->
        </v-col>
        <v-col
          v-if="editedItem.Type"
          cols="12"
          sm="12"
          style="_padding: 25px"
        >
          <!--a 
            v-if="isDev"
           :href="editedItem.MediaURL"
          >
          {{editedItem.MediaURL}}
          </a-->
          <event-form
            v-if="['event', 'eventCalendar'].indexOf(editedItem.Type) >= 0"
            :contentType="editedItem.Type"
            :locale="locale"
            @change="handleChangeEvent"
          />
          <image-only
            v-else-if="editedItem.Type === 'image'"
            :size="{ w: contentTypeData.Width, h: contentTypeData.Height }"
            :oldImg="editedItem.MediaURL"
            @change="handleChangeMedia"
            style="margin: 0px;"
            :simpleButton="false"
          />
          <video-file
            v-else-if="editedItem.Type === 'video'"
            :size="{ w: contentTypeData.Width, h: contentTypeData.Height }"
            :oldValue="editedItem.FileName ? {
                          name: editedItem.FileName,
                          size: editedItem.FileSize
                        } : null"
            @change="handleChangeMedia"
          />
          <QR
            v-else-if="editedItem.Type === 'qr'"
            :size="{ w: contentTypeData.Width, h: contentTypeData.Height }"
            :oldValue="editedItem.FileName ? {
                          name: editedItem.FileName,
                          size: editedItem.FileSize,
                          URL: editedItem.URL,
                        } : null"
            :mediaURL="editedItem.MediaURL"
            @change="handleChangeMedia"
            :defaultValues="editedItem"
          />
          <custom-content
            v-else-if="checkIsCustomContent(editedItem.Type)"
            :isClone="isClone"
            :editedID="editedItem.ID"
            :customContentTypeID="editedItem.Type"
            :locale="locale"
            :onChange="handleChangeCustomContent"
          />
        </v-col>
      </v-row>
      <v-row v-if="editedItem.Type !== 'video'">
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <custom-label
            :label="$t('content.time', locale).concat(':')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <interval-time
             v-model="editedItem.Time"
             style="width: 100px"
          />
        </v-col>
      </v-row>
      <v-row>
        <select-image-on-save />
      </v-row>
      <v-row style="padding: 10px;">
        <v-select
          v-model="editedItem.Hide"
          itemValue="id"
          itemText="name"
          dense
          label="Estado"
          hide-details
          :items="[{
            id: 0,
            name: 'Visible'
          },
          {
            id: 1,
            name: 'Oculto'
          }]"
          outlined
        />
      </v-row>
      <v-row>
        <availability
          v-model="editedItem.Availability"
          :locale="locale"
          @change="handleChangeAvailability"
        />
      </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="!isClone ? handleDelete : null"
      :onSaveItem="handleSave"
      :onClose="handleClose"
      :locale="locale"
    />
  </div>
</template>
<script>
  const defaultItem = {
    Name: '',
    Message: '',
    IsTemp: false,
    Time: 10,
    Type: null,
    Hide: 0,
    Availability: null,
    newMedia: null,
  }
  
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import ActionButtons from '@/components/ActionButtons'
  import IntervalTime from '@/components/intervalTimes/IntervalTime'
  import CustomLabel from '@/components/CustomLabel'
  import Availability from '@/components/Availability'
  import ImageOnly from './ImageOnly'
  import VideoFile from './VideoFile'
  import EventForm from './EventForm'
  import QR from './qr/Index.vue'
  import CustomContent from './CustomContent'
  import SelectImageOnSave from '@/components/SelectImageOnSave'

  export default{
    name: 'MediaForm',
    components: {
      ActionButtons,
      IntervalTime,
      CustomLabel,
      EventForm,
      ImageOnly,
      QR,
      CustomContent,
      Availability,
      SelectImageOnSave,
      VideoFile,
    },
    props: {
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      contentTypeData: {
        type: Object,
        required: true
      },
      allowedTypes: {
        type: Array,
        default: null,
      },
      isClone: {
        type: Boolean,
        default: false,
      },
      editedID: {
        type: String,
        default: null
      },
      onClose: {
        type: Function,
        required: true
      },
      onDelete: {
        type: Function,
        required: true
      }
    },
    data () {
      return {
        loading: false,
        editedItem: null,
      }
    },
    computed: {
      ...mapState('app',['locale']),
      isNew () {
        return this.editedID ? false : true
      },
      isDev () {
        return process.env.VUE_APP_ENV === 'development'
      },
    },
    watch: {
      editedID () {
        this.handleGetData()
      },
    },
    mounted () {
      this.loading = true
      this.handleGetData()
    },
    methods: {
      checkIsCustomContent (v) {
        return ['image', 'video', 'qr'].indexOf(v) === -1 ? true : false
      },
      handleGetData () {
        this.loading = true
        if (!this.editedID) {
          this.editedItem = JSON.parse(JSON.stringify(defaultItem))
          this.editedItem.ContentTypeDataID = this.contentTypeData.ID
          if (this.allowedTypes && this.allowedTypes.length >= 1) this.editedItem.Type = this.allowedTypes[0].value
          this.loading = false
        } else {
          api.getItem ('dviewcontent', `private/media/${this.contentTypeData.ID}/`, this.editedID)
          .then(response => {
            this.editedItem = response
            this.loading = false
          })
        }
      },
      handleSave () {
        if ((this.editedItem.MediaURL && this.editedItem.MediaURL.length > 0) || this.editedItem.newMedia || (this.checkIsCustomContent(this.editedItem.Type) && this.editedItem.customContent)) {
          
          if (!this.checkIsCustomContent(this.editedItem.Type)) this.editedItem.customContent = null

          if (this.editedItem.ID) {
            api.updateItem ('dviewcontent', `private/media/${this.contentTypeData.ID}/`, this.editedItem.ID.concat(this.isClone ? '/clone' : ''), this.editedItem)
              .then(() => {   
                this.onClose()
              })
              .catch(error => {
                alert(error)
              })
          } else { 
            api.addItem ('dviewcontent', `private/media/${this.contentTypeData.ID}`, this.editedItem)
              .then(() => {   
                this.onClose()
              })
          }
        } else {
          this.$store.state.app.mainAlert = {
            icon: 'mdi-cancel',
            message: this.$t(`content.messages.${this.editedItem.Type === 'video' ? 'noVideo' : 'noImage'}`, this.locale),
          }
        }
      },
      handleDelete () {
        this.onDelete(this.editedID)
      },
      handleClose () {
        this.onClose()
      },
      handleChangeAvailability (v) {
        this.editedItem.Availability = v
      },
      handleChangeMedia (v) {
        this.editedItem.newMedia = v
      },
      handleChangeCustomContent (v) {
        this.editedItem.customContent = v
      },
    }
  }
</script>
