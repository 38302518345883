<template>
  <div class="ext-box">
    <div class="int-box"
      :style="{ 'text-align': left ? 'left' : 'right' }"
    >
        <span>{{ label }}</span>
    </div>
  </div>
</template>
<script>
  export default{
    name: 'CustomLabel',
    props: {
      label: {
        type: String,
        required: true,
      },
      left: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>
<style scoped>
  div.ext-box {
    display: table;
    width:100%;
    height: 100%;
  }
  div.int-box { 
    display: table-cell;
    vertical-align: middle;
  }
</style>

