<template>
   <upload-video-file
    style="width: 100%"
    @change="handleChange"
    :oldValue="oldValue"
    :size="size"
  />
</template>
<script>
  import UploadVideoFile from '@/components/uploadVideoFile/Index'
  export default {
    components: {
      UploadVideoFile,
    },
    props: {
      oldValue: {
        type: Object,
        default: null,
      },
      size: {
        type: Object,
        default: null,
      },
    },
    methods: {
      handleChange (v) {
        const aux = v.name.split('.')
        v.ext =  aux.length > 1 ? aux[1] : 'mp4' 
        this.$emit('change', v)
      },
    },
  }
</script>

