<template>
  <upload-file-item
    :localeFile="locale"
    @change="handleChangeFile"
    :backgroundImage="backgroundImage"
    :size="size"
    :simpleButton="simpleButton"
    :circle="circle"
  />
</template>

<script>
  import { mapState } from 'vuex'
  import UploadFileItem from './UploadFileItem'
  export default {
    name: "upload-file",
    components: {
      UploadFileItem
    },
    props: {
      info: {
        type: Object,
        default: null
      },
      onDeleteFile: {
        type: Function,
        default: null
      },
      backgroundImage: {
        type: String,
        default: null,
      },
      size: {
        type: Object,
        default: null
      },
      simpleButton: {
        type: Boolean,
        default: false,
      },
      circle: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    methods: {
      handleChangeFile (file) {
        this.$emit('change', file)
      },      
    }
  }
</script>

