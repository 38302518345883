<template>
  <v-row justify="center">
    <!--v-col cols="12" md="12">
      <v-img :src="newImg" style="width: 400px;height:auto;"/>
    </v-col-->
    <v-col cols="12" md="12">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{ on, attrs }">
        <!--v-badge
          v-if="backgroundImg"
          color="primary"
          icon="mdi-pencil"
          overlap
        >dd
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            style="width: 80px;height: 80px;border-radius: 40px 2px 40px 40px;margin:10px;"
          >
            <v-img
              :src="backgroundImg"
              style="width: 80px;height: 80px;border-radius: 40px 2px 40px 40px;margin:10px;"
            />
          </v-btn>
        </v-badge-->

          <v-btn
            v-if="backgroundImg"
            v-bind="attrs"
            v-on="on"
            text
            color="primary"
            style="width: 80px;height: 80px;border-radius: 40px 40px 40px 40px;background-size:cover;"
            :style="{ 'background-image': `url(${backgroundImg})` }"
            x-large
            block
          >
            <v-icon>mdi-pencil</v-icon>
            <!--v-img
              :src="backgroundImg"
              style="width: 80px;height: 80px;border-radius: 40px 2px 40px 40px;margin:10px;"
            /-->
          </v-btn>
        <v-btn
          v-else
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          block
        >
        {{ $t('content.createQR', locale) }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
          :elevation="0"
        >
          <v-btn
            icon
            dark
            @click="handleClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('content.createQR', locale) }}</v-toolbar-title>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="primary"
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="handlePrepareSave"
              :disabled="loading"
            >
            {{ $t('common.save', locale) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-layout wrap style="padding:20px">
          <v-flex xs6>
            <v-text-field
              v-bind="{ 'outlined' : true}"
              v-model="URL"
              :label="$t('content.url', locale)"
              hide-details
              v-on:keyup.enter="handleGenerateQR"
              dense
            />
          </v-flex>
          <v-flex xs6>
            <center>
              <image-only 
                :size="size"
                :oldImg="oldImg"
                @change="handleChangeMedia"
                style="margin: 0px;"
                simpleButton
              />
            </center>
          </v-flex>
        </v-layout>
        <div
          ref="konvaini"
        >
          <center 
          >
            <div
              v-if="konvaSize"
              ref="konvaImg"
              :style="{
                'width': `${konvaSize.width}px`,
                'height': `${konvaSize.height}px`,
              }"
            >
              <konva-qr
                :size="konvaSize"
                :qrImg="qrImg"
                :bgImg="bgImg"
                :qrWidth="qrWidth"
                :force="force"
                :onSave="handleSave"
              />
            </div>
          </center>
        </div>
      </v-card>
    </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  const marginTop = 200 // utilizado para compensar la barra superior
  const width = window.innerWidth
  const height = window.innerHeight - marginTop

  import { mapState } from 'vuex'
  import QRCode from 'qrcode'
  import utils from '@/services/utils'
  import ImageOnly from '../ImageOnly'
  import KonvaQr from './KonvaQR'

  export default {
    name: 'QR',
    components: {
      ImageOnly,
      KonvaQr,
    },
    props: {
      size: {
        type: Object,
        default: null,
      },
      oldImg: {
        type: String,
        default: null,
      },
      oldValue: {
        type: Object,
        default: null,
      },
      mediaURL: {
        type: String,
        default: null,
      },
      defaultValues: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        loading: false,
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        URL: null,
        qrImg: null,
        bgImg: null,
        newImg: null,
        qrWidth: 400,
        fileInfo: {},
        konvaSize: null,
        awaitingQR: false,
        backgroundImg: null,
        force: null,
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      /*dialog (v) {
        if (v)
          this.$nextTick(() => {
            console.log('jhm3', this.$refs.konvaImg.offsetHeight)
          })
      },*/
      defaultValues () {
        this.checkDefaultValues()
      },
      URL () {
        this.fileInfo.URL = this.URL
        if (!this.awaitingQR) {
          setTimeout(() => {
            this.handleGenerateQR()
            this.awaitingQR = false
          }, 1000) // 1 sec delay
        }
        this.awaitingQR = true
      },
    },
    mounted () {
      this.checkDefaultValues()
      this.backgroundImg = this.mediaURL
      this.konvaSize = utils.getScale(width, height, this.size.w, this.size.h)
    },
    methods: {
      checkDefaultValues () {
        if (!this.defaultValues) return
        if (this.defaultValues.URL && this.defaultValues.URL.length > 0) this.URL = this.defaultValues.URL
      },
      handleClose () {
        this.bgImage = null
        this.dialog = false
      },
      handleChangeMedia (v) {
        this.bgImg = v.base64
      },
      handleGenerateQR () {
        QRCode.toDataURL(this.URL, {
          type: 'image/png',
          width: this.qrWidth,
          color: {
            dark: '#000000',  // Blue dots
            light: '#FFFFFF'
          }
        })
        .then(url => { this.qrImg = url })
        .catch(err => { console.error(err) })
      },
      handlePrepareSave () {
        this.force = new Date().toISOString()
      },
      async handleSave () {
        if (!this.bgImg) {
          this.handleClose () // if the image is not loaded it close directly
          return
        }
        this.loading = true
        const options = {
          type: 'dataURL'
        }
        const el = this.$refs.konvaImg

        
        this.newImg = await this.$html2canvas(el, options)
        this.backgroundImg = this.newImg
        //console.log('dddd', this.backgroundImg)

        if (this.newImg) {
          this.fileInfo.base64 = this.newImg // update img with crop
          this.backgroundImage = this.newImg.split(':')[1]
        }
        this.$emit('change', {
          ...this.fileInfo,
          ext: 'png'
        })

        this.loading = false
        // this.$emit('change', this.newImg)
        this.handleClose()
      }
    },
  }
</script>

